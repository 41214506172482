export default function Column({news}) {
  const column_css = "column " + news.type;
  const ad = news.type == 'ad'? 'Ad' : null;
  const story = news.type == 'story'? 'Story' : null;

  return (
    <div class={column_css}>
      <div class="tick ad">{ad}</div>
      <div class="tick story">{story}</div>
      <div class="head">
        <span class="headline hl">{news.headline}</span>
        {news.headimage &&
          <img class="media" src={news.headimage} />
        }
        {news.headimage_caption &&
          <figcaption class="figcaption">{news.headimage_caption}</figcaption>
        }
        {news.sub_headline &&
          <span class="headline sub_hl">{news.sub_headline}</span>
        }
      </div>

      {news.content &&
        <div class="details">
          {news.content}
        </div>
      }

      <div class="head">
      {news.midimage1 &&
        <img class="media" src={news.midimage1} />
      }
      {news.midimage1_caption &&
        <figcaption class="figcaption">{news.midimage1_caption}</figcaption>
      }
      </div>

      {news.second_content &&
        <div class="details">
          {news.second_content}
        </div>
      }

      <div class="head">
        {news.midimage2 &&
          <img class="media" src={news.midimage2} />
        }
        {news.midimage2_caption &&
          <figcaption class="figcaption">{news.midimage2_caption}</figcaption>
        }
      </div>

      {news.third_content &&
        <div class="details">
          {news.third_content}
        </div>
      }
    </div>
  );
}
