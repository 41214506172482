const time_zone = 'en-US';
const state = 'NY';

export default function SubHead() {
  let today = new Date();
  let year = today.getFullYear(time_zone);
  let month = today.toLocaleString(time_zone, {month: 'long'});
  let day = today.getDate();
  let week_day = today.toLocaleString(time_zone, {weekday: 'long'});

  return (
    <div class="subhead">
      {state} - {week_day} {month} {day}, {year}
    </div>
  );
}
