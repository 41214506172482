import HeaderObjectsWrapper from './HeaderObjectsWrapper.js'
import SubHead from './SubHead.js'

export default function Head() {
  return (
    <div class="head">
      <HeaderObjectsWrapper />
      <SubHead />
    </div>
  );
}
