import Quote from './Quote.js'
import Header from './Header.js'

export default function HeaderObjectsWrapper() {
  return (
    <>
      <Quote />
      <Header />
    </>
  );
}
