import Head from './Head.js'
import Content from './Content.js'
import Footer from './Footer.js'

function App() {
  return (
    <>
      <Head />
      <Content />
      <Footer />
    </>
  );
}

export default App;
