import Collumn from './Collumn.js'

export default function Content() {
  const newslist = [
    {
      'id': 1,
      'type': 'news',
      'headline': 'USLEC 8기 졸업식',
      'headimage': '/images/1_headimage.jpg',
      'headimage_caption': '왼쪽부터 제이미 리, 크리스챤 정, 미쉘 팍, 유민 노, 하나 최',
      'sub_headline': '한인 2세 양성을 향한 USLEC의 열정이 결실을 맺는 순간',
      'content':  '지난 9월 1일 극단 춤누리의 난타 공연을 오프닝으로 시작된 USLEC 8기 인턴쉽 학생들의 졸업식은 즐거운 분위기에서 진행되었다. 학부모님들이 정성껏 준비한 식사는 학생들과 스페셜게스트들과의 만남을 더욱 자연스럽게 만들어주었고, 학생들은 모두들 한껏 들떠 있었다. 행사 진행자는 역시나 Robert Kugler 경찰서장이 맡아서 진행을 하였다. 이날 National Anthem은 8기 학생 Hannah Jung이 불러주었고, Kugler서장의 기도로 엄숙한 가운데 졸업식이 진행되었다. 이날 초대된 스페셜게스트는 DEA요원 Anthony Jennings, IACLEA의 Paul Cell서장, Ridgewood경찰서장 Jacqueline Luthcke등 각계 정부기관에서 활발히 활동하고 있는 관계자들로 구성되었다. 스페셜게스트의 연설이 끝나고 학생들의 소감과 학부모님들의 소감등 한 해를 돌아보는 시간을 가졌고, 이후 각종 장학금수여 및 수료증 전달이 있었다. 단체의 대표를 일임하고 있는 다니엘 송 대표는 이날 학부모님들과 스탭들에게 감사의 말을 전했고, 앞으로도 아이들의 미국주류사회 진입을 위해 애쓰겠다는 소감을 남기며 졸업식은 성황리에 마무리가 되었다.',
      'midimage1': null,
      'midimage1_caption': null,
      'second_content': null,
      'midimage2': null,
      'midimage2_caption': null,
      'third_content': null,
    },
    {
      'id': 2,
      'type': 'story',
      'headline': '나의 고양이 입양기',
      'headimage': null,
      'headimage_caption': null,
      'sub_headline': 'by Eleanor S. Lim from Englewood',
      'content': '1년 전쯤 우리집 뒷 마당에서 고양이 가족을 보았습니다. 엄마 고양이가 2마리의 아기 고양이를 데리고 다녔는데 그중 한마리가 유독 엄마를 잘 따르고 매우 활발해 보였습니다. 그 날 이후로 이따금씩 집에서 남는 고기를 나누어 주다가 고른 영양섭취를 위해 사료를 주는 것이 좋다는 어떤 유튜버의 얘기를 듣고 사료를 사다 주기 시작했습니다. 그러던 어느날 엄마고양이는 천진난만한 아기고양이에게 "히씩"거리기 시작했고 아기를 돌보아 주지 않는 것 같아 보였습니다. 아마도 독립심을 기르게 해주려고 그랬던것 같은데 그 모습을 보는 제 마음이 너무 짠해서 아기 고양이에게 더욱 잘해주기 시작했습니다. 그러다 겨울이 왔고, 바깥의 고양이가 마음에 걸려 고양이를 들이기로 결심했습니다. ',
      'midimage1': '/images/2_midimage.gif',
      'midimage1_caption': '처음 집안에 들어와 모든것이 낯선 고양이',
      'second_content': '고양이 가방을 사고 고양이를 어렵게 가방에 넣어 동물병원에 데려가 각종 검사와 예방 주사를 맞히고 기생충 약도 받아왔습니다. 병원에서 기생충약 먹고 앞으로는 절대 바깥 출입을 못하도록 하라고 당부를 했습니다. 하지만 이는 실패로 돌아가고 말았습니다. 한번 야생에 살았던 고양이를 집안에 묶어둔다는 것이 얼마나 잔인한 일인지 고양이의 모습을 보면 알수 있습니다. 고양이는 끊임없이 바깥으로 탈출하려고 온갖 애를 썼고 그 모습이 너무 애잔해서 바깥 출입을 시켜주었습니다. 지금은 밥은 집안에서 먹고 나가서 놀다가 다시 들어와서 자거나 현관앞에 놓인 의자에 앉아있기도 합니다. 아마도 기생충이 다시 생겼겠지만 고양이는 너무도 행복해 보이고 그런 고양이를 보는 저도 너무 행복합니다.',
      'midimage2': null,
      'midimage2_caption': null,
      'third_content': null,
    },
    {
      'id': 3,
      'type': 'news',
      'headline': '뉴저지주 한인 후보들 선거운동 본격화',
      'headimage': '/images/3_headimage.jpg',
      'sub_headline': '팰팍 시장선거 폴 김·스테파니 장 후원행사 개최',
      'content': '본선거가 두 달 앞으로 다가온 가운데 뉴저지주 주요 한인 정치인들이 후원행사를 개최하는 등 본격적인 선거운동에 들어갔다. 뉴저지주는 올 본선거에 연방하원의원 3선에 도전하는 앤디 김(민주·3선거구) 후보와 함께 북부 뉴저지 버겐카운티를 중심으로 각 타운에서 한인 시장과 시의원 후보들이 출마해 치열한 선거전이 예상되고 있다. 팰리세이즈파크(팰팍)에서는 시장직을 놓고 두 명의 한인 후보인 폴 김(민주) 시의원과 스테파니 장(공화) 시의원이 각각 후원행사를 여는 등 캠페인을 본격화하고 있다. 지난 봄 민주당 공천을 받고 예비선거를 통과한 김 후보는 오는 9일 팰팍 시루연회장에서 후원의 밤 행사를 갖는다. 이날 행사에는 버겐카운티 주요 타운들을 지역구로 하고 있는 조시 고트하이머(민주·5선거구) 연방하원의원이 참석할 예정이다. 김 후보는 이번 선거에 제이슨 김·마이크 비에트리 시의원 후보와 러닝메이트를 이뤘는데, 8일 오후 5시30분에 팰팍의 사무실에서 선거운동과 관련된 주요 현안을 논의하는 회의를 개최한다. 공화당의 장 후보는 오는 22일 포트리 더블트리호텔에서 후원의 밤 행사를 갖는다. 이날 행사는 후보의 공약발표 등과 함께 한국무용 등 공연도 함께 펼쳐진다. 장 후보는 같은 공화당 소속의 우윤구·원유봉 시의원 후보와 함께 선거운동을 벌이고 있는데, 이에 앞서 8일 오전 11시에는 기자회견을 연다. 이와 함께 크리스 김(잉글우드클립스·민주), 피터 서(포트리·민주), 대니얼 박(테너플라이·민주), 기드온 최(클로스터·민주) 시의원 후보 등 한인 후보들도 선거 두 달을 남기고 마지막 총력전에 들어갔다. 박종원 기자',
      'midimage1': null,
      'midimage1_caption': null,
      'second_content': null,
      'midimage2': null,
      'midimage2_caption': null,
      'third_content': null,
    },
    {
      'id': 4,
      'type': 'ad',
      'headline': '유키하나 일식뷔페',
      'headimage': '/images/4_headimage.jpg',
      'sub_headline': '유키하나 All-You-Can-Eat 일식뷔페',
      'content': '저렴한 가격으로 푸짐한 외식을 할수 있는 곳을 찾으신다면 유키하나를 강력히 추천드립니다.',
      'midimage1': '/images/4_midimage1.jpg',
      'midimage1_caption': null,
      'second_content': '각 종 초밥과 푸짐한 요리들을 맛볼수 있는 곳입니다. BYOB로 와인이나 사케등 원하시는 주류를 직접 가지고 오셔서 맛있는 일식을 즐기세요.',
      'midimage2': '/images/4_midimage2.jpg',
      'midimage2_caption': null,
      'third_content': '유키하나는 초밥뿐만 아니라 일식튀김, 볶음우동, 사시미등 각종 일식 진미를 맛볼수 있는 가성비 최고의 외식장소입니다. 가족과 연인 친구들과 함께 오셔서 즐거운 추억을 만들고 가시도록 최선을 다하겠습니다.',
    },
    {
      'id': 5,
      'type': 'news',
      'headline': '올 가을 팰팍 센트럴블러바드 보수',
      'headimage': '/images/5_headimage.jpg',
      'headimage_caption': null,
      'sub_headline': null,
      'content': '뉴저지 팰리세이즈팍의 센트럴블러바드 도로가 올 가을께 보수된다. 팰팍 폴 김 시의원과 제이슨 김 교육위원 등은 "센트럴블러바드를 관할하는 버겐카운티정부에 요청해 올 가을께 보수 공사가 시작될 수 있게 됐다. 지난 20년간 제대로 보수가 이뤄지지 않았지만 이번에 카운티정부 예산으로 개선이 이뤄지게 됐다"고 밝혔다. <폴 김 팰팍 시장 민주당 후보 선거본부 제공>',
      'midimage1': null,
      'midimage1_caption': null,
      'second_content': null,
      'midimage2': null,
      'midimage2_caption': null,
      'third_content': null,
    },
  ]

  return (
    newslist.map(news => {
      return <Collumn key={news.id} news={news} />
    })
  );

  // return (
  //   <div class="content">
  //     <div class="collumns">
  //       {
  //         newslist.map(news => {
  //           return <Collumn key={news.id} news={news} />
  //         })
  //       }
  //     </div>
  //   </div>
  // );
}
